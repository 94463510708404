import React, {useState,useEffect} from 'react'
import {Button,Input} from 'antd';
import {useHistory} from "react-router-dom";
import logo from  '../../assets/login_logo@3x.png'
import logoTop from '../../assets/login_logo_top@3x.png'
import hideIcon from '../../assets/login_btn_hide@3x.png'
import revealIcon from  '../../assets/login_btn_reveal@3x.png'
import Modal from './component/modal'
import HTTP from "../../utils/HTTP";
const Register = () => {
  const history = useHistory()
  const [select, setSelect] = useState(1)
  const [show,setShow]=useState(false)
  const [timeChange, setTimeChang] = useState(null);
  const [time, setTime] = useState(59);
  const [disabled, setDisabled] = useState(false);
  const [timerText, setTimerText] = useState('59s后重发');
  const [send, setSend] = useState(false);
  const [mobileNum,setMobileNum]=useState('')
  const [emailNum,setEmailNum]=useState('')
  const [passwordNum , setPasswordNum]= useState('')
  const [codeNum,setCodeNum]=useState('')
  const [inviteNum,setInviteNum]=useState(window.location?.search?.split("=")?.[1] ?? '')
  const [open,setOpen]=useState(false)
  const [msg,setMsg]=useState('')
  const [msgChange,setMsgChange]=useState(null)
  // 获取验证码计时
  useEffect(() => {
    if (time > 0 && time < 60) {
      setTimerText(`重新发送${time}s`);
    } else {
      setTimeChang(clearInterval(timeChange));
      setSend(false);
      setTime(60);
      setDisabled(false);
      setTimerText('发送');
    }
  }, [time]);
  // 弹出框定时
  useEffect(()=>{
      setMsgChange(clearInterval(msgChange))
    if(open){
      setMsgChange(setInterval(()=>{
        setOpen(false)
      },1000))
    }
  },[open])
  const getCode = () => {
    setMsgChange(clearInterval(msgChange))
    if (select == 1 && !mobileNum) {
      setOpen(true)
      setMsg('未输入手机号')
      return;
    }
    if (select == 2  && !emailNum) {
      setOpen(true)
      setMsg('未输入邮箱')
      return;
    }
    setSend(true);
    setDisabled(true);
    setTimeChang(
      setInterval(() => {
        setTime((t) => --t);
      }, 1000),
    );
    if (select == 1) {
      HTTP.postForm('/api/h5/sms/sendCommonSMS', {
        mobile: mobileNum,
      })
        .then((res) => {
          if (res.status === 10000) {
            setOpen(true)
            setMsg(res.message)
          }else {
            setOpen(true)
            setMsg(res.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      HTTP.postForm('/api/h5/email/sendCommonEmail', {
        email: emailNum,
      })
        .then((res) => {
          if (res.status === 10000) {
            setOpen(true)
            setMsg(res.message)
          }else {
            setOpen(true)
            setMsg(res.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const onSubmit = () =>{
    if (select == 1) {
      HTTP.postForm('/api/h5/auth/quantityRegisterByMobile', {
        mobile: mobileNum,
        password:passwordNum,
        code:codeNum,
        invite_code:inviteNum
      })
        .then((res) => {
          if (res.status === 10000) {
            setOpen(true)
            setMsg('注册成功')
            setTimeout(()=>{
              history.push('/download')
            },1000)
          }else {
            setOpen(true)
            setMsg(res.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      HTTP.postForm('/api/h5/auth/quantityRegisterByEmail', {
        email: emailNum,
        password:passwordNum,
        code:codeNum,
        invite_code:inviteNum
      })
        .then((res) => {
          if (res.status === 10000) {
            setOpen(true)
            setMsg('注册成功')
            setTimeout(()=>{
              history.push('/download')
            },1000)
          }else {
            setOpen(true)
            setMsg(res.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  return (
    <div className={'body'} style={{
      background: '#070C41',
    }}>
      <div style={{
        flex:1
      }}>
        <div className={'top'}>
          <div style={{
            background:'#000000',
            display:'flex',
            padding:'12px 14px 9px 14px',
            justifyContent:'space-between',
            alignItems: 'center',
          }}>
              <img src={logoTop} style={{
                width:110,
                height:30
              }}/>

              <Button size="small" style={{
                height:27,
                width:60,
                background:'#000000',
                color:'#FFFFFF'
              }}
              onClick={()=>{
                history.push('/download')
              }}
              >
                下载
              </Button>
          </div>
          <div style={{
            marginTop:100,
            display:'flex',
            justifyContent:'center',
          }}>
            <img src={logo} style={{
              width:65,
              height:65
            }} />
          </div>
        </div>
        <div style={{
          display:'flex',
          alignItems:'center',
          padding:'0 14px'
        }}>
          <div style={{
            width:65,
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Button type="text" style={{
              color:select == 1 ?'#FFFFFF' : '#AEBBC6',
              fontWeight:900,
              padding:0,
              height: 22,
              fontSize: select == 1 ? 16 :14
            }} onClick={()=>{
              setSelect(1)
            }}>手机注册</Button>
            <div style={{
              marginTop:13,
              height:2,
              width:30,
              background: select == 1 ? '#FFFFFF' :'#070C41'
            }} />
          </div>
          <div style={{
            marginLeft:34,
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center'
          }} >
            <Button type="text" style={{
              color:select == 2 ? '#FFFFFF' : '#AEBBC6',
              fontWeight:select == 2 ? 900 : 500,
              padding:0,
              height: 22,
              fontSize:select == 2 ? 16 :14
            }} onClick={()=>{
              setSelect(2)
            }}>邮箱注册</Button>
            <div style={{
              marginTop:13,
              height:2,
              width:30,
              background: select == 2 ? '#FFFFFF' :'#070C41'
            }} />
          </div>
        </div>
        <div style={{
          marginTop:30,
          padding:'0 14px'
        }}>
          <Input bordered={false} placeholder={ select ==1 ?'输入你的手机':'输入你的邮箱'} style={{
            borderRadius:5,
            background:'#4860A0',
            height:45,
            color:'#FFFFFF',
            fontSize:16
          }} onChange={(e)=>{
              setMobileNum(e.target.value)
              setEmailNum(e.target.value)
          }}/>
          <div style={{display:'flex',alignItems:'center', marginTop:20,borderRadius:5,
            background:'#4860A0',
            height:45,}}>
            <Input bordered={false}
                   placeholder={'输入你的密码'} style={{
              color:'#FFFFFF',
              fontSize:16
            }}
            type={show?'text':'password'}
                   onChange={(e)=>{
                     setPasswordNum(e.target.value)
                   }}
            />
            <div style={{
              width:160,
              height:45,
              display:'flex',
              alignItems:'center',
              justifyContent:'flex-end'
            }}>
              <Button type="link" style={{
                padding:0,
                marginRight:20
              }} onClick={()=>{
                setShow(!show)
              }
              }>
                <img src={show ? revealIcon:hideIcon} style={{
                  width:16,
                  height:11
                }}/>
              </Button>
            </div>
          </div>
          <div style={{display:'flex',alignItems:'center', marginTop:20,borderRadius:5,
            background:'#4860A0',
            height:45,}}>
            <Input bordered={false}
                   placeholder={'输入验证码'} style={{
              color:'#FFFFFF',
              fontSize:16
            }}   onChange={(e)=>{
              setCodeNum(e.target.value)
            }} />
            <div style={{
              width:160,
              height:13,
              display:'flex',
              alignItems:'center',
              justifyContent:'flex-end',
              borderLeft:'1px solid grey'
            }}>
              <Button type="link" disabled={disabled} style={{
                padding:0,
                marginRight:20,
                color:'#FFFFFF'
              }} onClick={()=>{
                getCode()
              }
              }>
                {send ? timerText : '发送'}
              </Button>
            </div>
          </div>
          <Input
            bordered={false}
            placeholder={'输入邀请码'}
            value={inviteNum}
            style={{
            marginTop:20,
            borderRadius:5,
            background:'#4860A0',
            height:45,
            fontSize:'16px',
            color:'#FFFFFF'
          }}   onChange={(e)=>{
            setInviteNum(e.target.value)
          }}/>
        </div>
      </div>
      <div style={{
        // marginTop:10,
        // height:50
        padding:'10px 14px'
      }}>
       <div>
          <span style={{
            fontSize:14,
            color:'#FFFFFF'
          }}>注册即表示已同意</span><span style={{
            color:'#4987E9',
         fontSize:14
       }}>《用户协议》</span>
       </div>
        <Button style={{
          marginTop:20,
          width:'100%',
          height:50,
          background:'#4987E9',
          borderColor:'#4987E9',
          borderRadius:5,
          color:'#FFFFFF',
          fontWeight:900
        }} onClick={()=>{
          onSubmit()
        }}>注册
        </Button>
      </div>
      {
        open && (
          <Modal msg={msg}/>
        )
      }
    </div>
  )
}

export default Register;
