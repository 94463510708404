import qs from 'qs';
import axios from 'axios';

export  default class HTTP {
  static getFullUrl(url, timeout) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: timeout || 30000,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(
            `HTTP Response error ${url}:`,
            JSON.stringify(error),
          );
          reject(error);
        });
    });
  }

  static postFullUrl(url, data, timeout) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, qs.stringify(data), {
          timeout: timeout || 30000,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(
            `HTTP Response error ${url}:`,
            JSON.stringify(error),
          );
          reject(error);
        });
    });
  }

  static get(url, token = undefined, timeout) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          timeout: timeout || 30000,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(
            `HTTP Response error ${url}:`,
            JSON.stringify(error),
          );
          reject(error);
        });
    });
  }

  static postForm(url, data, timeout = 15000, token = undefined) {
    return new Promise((resolve, reject) => {
      console.debug(`HTTP Required ${url}:`, data);
      axios
        .post(url, qs.stringify(data), {
          timeout: timeout,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(
            `HTTP Response error ${url}:`,
            JSON.stringify(error),
          );
          if (error?.status !== 99888) {
            reject(error);
          }
        });
    });
  }

  static post(url, data, timeout = 15000, token = undefined) {
    return new Promise((resolve, reject) => {
      console.debug(`HTTP Required ${url}:`, data);
      axios
        .post(url, data, {
          timeout: timeout,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(
            `HTTP Response error ${url}:`,
            JSON.stringify(error),
          );
          if (error?.status !== 99888) {
            reject(error);
          }
        });
    });
  }
}
