import React,{useEffect,useState} from 'react'
import {Button} from 'antd';

import logoTop from '../../assets/login_logo_top@3x.png'
import HTTP from "../../utils/HTTP";
import QRCode from 'qrcode.react';
const Download = () => {
  const [text,setText]=useState(undefined)
  const [textUrl,setTextUrl] = useState('')
  useEffect(()=>{
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      setText("ios")
    } else {
      setText("android")
    }
  },[])
  useEffect(()=>{
    getUrlCode()
  },[text])
  const getUrlCode = () =>{
    HTTP.postForm('/api/h5/common/downloadUrl', {
      type:text
    })
      .then((res) => {
        if (res.status === 10000) {
          setTextUrl(res.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className={'body'} style={{
      background: '#070C41',
    }}>
     <div style={{
       marginTop:30,
       padding:'0 14px'
     }}>
       <img src={logoTop} style={{
         width:110,
         height:30
       }}/>
     </div>
      <div className={'content'} style={{
        marginTop:56,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        position:'relative'
      }}>
        <QRCode
          value={textUrl}// 生成二维码的内容
          size={125} // 二维码的大小
          fgColor="#000000" // 二维码的颜色
        />
       <div style={{
         position:'absolute',
         bottom:0,
       }}>
         <p style={{
           color:'#5794F1',
           fontSize:15
         }}>提示“未授权信企业开发者”解决方案</p>
       </div>
      </div>
      <div style={{
        marginTop:14,
        flex:1,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
      }}>
        <Button style={{
          width:205,
          height:50,
          borderRadius:25,
          background:'#4D6BA7',
          borderColor:'#4D6BA7',
          color:'#FFFFFF',
          fontWeight:900
        }}
        onClick={()=>{
          window.location.href = textUrl
        }}
        >
          点击下载
        </Button>
        <p style={{marginTop:30,color:'#858CA3',
        fontSize:14}}>或用手机扫码二维码安装</p>
      </div>
    </div>
  )
}

export default Download;
