import './App.less';
import React from 'react'
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Register from "./pages/Register";
import Download from "./pages/Download";
const App=()=> {
  return (
    <div className="App">
      <Router>
        <Route exact path='/'>
          <Register />
        </Route>
        <Route exact path='/download'>
          <Download/>
        </Route>
      </Router>
    </div>
  );
}

export default App;
