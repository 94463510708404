import React from 'react'

const Modal = (props) =>{
  return (
    <div style={{
      width:'100%',
      position:'absolute',
      top: '47%',
      display:'flex',
      justifyContent:'center',
      textAlign:'center',
    }}>
      <p style={{
        background:'#1a3660',
        padding:5,
        color:'#FFF',
        borderRadius:5
      }}>{props.msg}</p>
    </div>
  )
}

export default Modal
